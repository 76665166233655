import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { ActionsEmptyStateVm } from './actions-empty-state-vm'
import { CreateFormModalButton } from 'components/create-form-modal'
import { EmptyActionsSvg } from './empty-actions-svg'
import { User } from 'store/modules/users'
import styles from './styles.module.scss'

export type ActionsEmptyStateVariant = 'card' | 'sidebar'

export type ActionsEmptyStateProps = {
  className?: string
  createdFromId?: string
  createdFromType?: string
  skillId?: string
  source: string
  user: User
  variant: ActionsEmptyStateVariant
}

export const ActionsEmptyState: React.VFC<ActionsEmptyStateProps> = observer(
  (props) => {
    const {
      className,
      createdFromId,
      createdFromType,
      skillId,
      source,
      user,
      variant,
    } = props

    const vm = React.useMemo(() => {
      return new ActionsEmptyStateVm(source, user, variant)
    }, [source, user, variant])

    const initialSkillIds = skillId ? [skillId] : undefined

    return (
      <div
        className={cn(
          'flex flex-col items-center',
          styles[`${variant}-container`]
        )}
      >
        {vm.showSvg && <EmptyActionsSvg />}
        <div
          className={cn(
            className || 'max-w-[522px]',
            vm.textAlignmentClassName
          )}
        >
          <h2 className={cn('text-gray-900', styles[`${variant}-title`])}>
            {vm.title}
          </h2>
          <p className="mt-2 text-gray-500 text-sm">
            Actions help you record what you’ll do next to progress in your
            skill and career growth.
          </p>
          <CreateFormModalButton
            className={vm.buttonClassName}
            createdFromId={createdFromId}
            createdFromType={createdFromType}
            initialSkillIds={initialSkillIds}
            initialUserIds={[user.id]}
            label={'Add Action'}
            source={vm.buttonSource}
            tabs={['action']}
            user={user}
            variant={vm.buttonVariant}
          />
        </div>
      </div>
    )
  }
)
