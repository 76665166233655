import { ActionsEmptyStateVariant } from './actions-empty-state'
import { User } from 'store/modules/users'

export class ActionsEmptyStateVm {
  constructor(
    private source: string,
    private user: User,
    private variant: ActionsEmptyStateVariant
  ) {}

  get buttonClassName() {
    switch (this.variant) {
      case 'card':
        return `btn btn-brand btn-sm mt-5`
      case 'sidebar':
        return `btn max-w-none mt-3 w-full`
    }
  }

  get buttonSource() {
    return `${this.source}-empty-state`
  }

  get buttonVariant() {
    switch (this.variant) {
      case 'card':
        return 'default'
      case 'sidebar':
        return 'outline'
    }
  }

  get showSvg() {
    return this.variant === 'card'
  }

  get textAlignmentClassName() {
    switch (this.variant) {
      case 'card':
        return 'text-center'
      case 'sidebar':
        return 'text-left'
    }
  }

  get title() {
    switch (this.source) {
      case 'skill-page':
        return 'Related Actions'
      default:
        return 'Create an Action'
    }
  }
}
